import React, { useContext } from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import Column from '../layouts/Column'
import { graphql } from 'gatsby'
import PagePadding from '../components/PagePadding/PagePadding'
import SkillCheck from '../components/SkillCheck/SkillCheck'
import InfoBlockCourse from '../components/InfoBlockCourse/InfoBlockCourse'
import TimeOverview from '../components/TimeOverview/TimeOverview'
import SignUpFormModal, {
	SignUpFormContextProvider,
} from '../components/SignUpFormModal/SignUpFormModal'
import Course from '../components/SignUpForms/Course/Course'
import { transformTableData } from '../utils/transformTable'

import FullWidthImageCarousel from '../components/FullWidthImageCarousel/FullWidthImageCarousel'
import { Helmet } from 'react-helmet'
import HeroBigAsset from '../components/HeroBigAsset/HeroBigAsset'
import ReviewCarousel from '../components/ReviewCarousel/ReviewCarousel'
import { HeaderContext } from '../components/Header/Header'
import Title from '../components/Title/Title'
import {
	getAllReviews,
	getCourseData,
	getAllDays,
	getAllCourseHeaders,
	filterEmptyDays,
	getAllVariations,
} from '../utils/courseUtils'

export default function CourseTemplate(props) {
	const wordpressAcfCursus = props.data.wordpressAcfCursus
	const allWordpressAcfReview = props.data.allWordpressAcfReview
	const HeaderCTX = useContext(HeaderContext)

	const reviewCards = getAllReviews(allWordpressAcfReview)

	const Info = getCourseData(wordpressAcfCursus, HeaderCTX)

	const SecondInfo = {
		course_description: wordpressAcfCursus.acf.description,
	}
	const days = getAllDays(wordpressAcfCursus)
	const headers = getAllCourseHeaders(wordpressAcfCursus)
	const daysFormatted = transformTableData(days, headers)

	const experience = Object.values(wordpressAcfCursus.acf.experience)

	const variationDays = getAllVariations(wordpressAcfCursus)
	const definiteVariations = filterEmptyDays(variationDays)

	return (
		<SignUpFormContextProvider>
			<SecondaryLayout>
				<Helmet>
					<title>In Balans Alkmaar | {Info.title}</title>
					<meta
						name="description"
						content={Info.course_description}
					/>
				</Helmet>

				{wordpressAcfCursus.acf.pictures &&
				wordpressAcfCursus.acf.pictures[0] &&
				wordpressAcfCursus.acf.pictures[0].src ? (
					<HeroBigAsset
						image={{
							small:
								wordpressAcfCursus.acf.pictures &&
								wordpressAcfCursus.acf.pictures[0] &&
								wordpressAcfCursus.acf.pictures[0].src
									? wordpressAcfCursus.acf.pictures[0].src
									: '/images/inbalans-image-10.jpg',
							large:
								wordpressAcfCursus.acf.pictures &&
								wordpressAcfCursus.acf.pictures[0] &&
								wordpressAcfCursus.acf.pictures[0].src
									? wordpressAcfCursus.acf.pictures[0].src
									: '/images/inbalans-image-10.jpg',
							alt:
								wordpressAcfCursus.acf.pictures &&
								wordpressAcfCursus.acf.pictures[0] &&
								wordpressAcfCursus.acf.pictures[0].alt_text &&
								wordpressAcfCursus.acf.pictures[0].alt_text
									.length > 2
									? wordpressAcfCursus.acf.pictures[0].alt
									: 'Cursus bij In Balans Alkmaar',
						}}
						course={true}
					/>
				) : null}
				<PagePadding>
					<Column>
						<InfoBlockCourse course={Info} />
					</Column>
				</PagePadding>
				{wordpressAcfCursus.acf.pictures &&
				wordpressAcfCursus.acf.pictures.length > 2 ? (
					<FullWidthImageCarousel
						className={'full-width__image-carousel'}
						images={wordpressAcfCursus.acf.pictures}
					/>
				) : null}
				<PagePadding>
					<Column>
						<SkillCheck experience={experience} />

						{Boolean(daysFormatted.length) > 0 && (
							<>
								<Title
									heading={2}
									text={wordpressAcfCursus.acf.title_course}
								/>
								<TimeOverview days={daysFormatted} />
							</>
						)}

						{Boolean(definiteVariations.length) > 0 &&
							definiteVariations.map((w, i) => (
								<>
									<Title heading={2} text={w[1]} />
									<TimeOverview
										days={transformTableData(
											w[0].body,
											w[0].header.map(
												(header) => header.c,
											),
										)}
									/>
								</>
							))}
						<InfoBlockCourse course={SecondInfo} />
					</Column>
				</PagePadding>
				{reviewCards.length >= 3 && (
					<ReviewCarousel
						className={'review__carousel'}
						cards={reviewCards}
					/>
				)}
			</SecondaryLayout>
			<SignUpFormModal>
				<Course courseData={wordpressAcfCursus} days={daysFormatted} />
			</SignUpFormModal>
		</SignUpFormContextProvider>
	)
}

export const query = graphql`
	query($wordpress_id: Int!) {
		wordpressAcfCursus(wordpress_id: { eq: $wordpress_id }) {
			acf {
				tonen
				kosten
				kosten2
				cursusnaam
				customId
				koptekst
				soort
				periode
				about_course {
					paragraph_1
					paragraph_2
					paragraph_3
					title_1
					title_2
					title_3
				}
				times {
					body {
						c
					}
					header {
						c
					}
				}
				pictures {
					alt: alt_text
					src: source_url
				}
				category
				description
				end_date
				price
				start_date
				title_course
				lesson_duration
				lesson_amount
				age
				entree
				experience {
					experience_1
					experience_2
					experience_3
					experience_4
					experience_5
					experience_6
				}
				variations {
					naam_variatie
					entree_kosten
					variation_startdate
					variation_enddate
					variation_description
					prijs
					customid
					leeftijd
					times {
						body {
							c
						}
						header {
							c
						}
					}
				}
			}
		}
		allWordpressAcfReview(
			filter: { acf: { type_review: { eq: "Cursussen" } } }
		) {
			edges {
				node {
					acf {
						age
						level
						name
						review
					}
				}
			}
		}
	}
`
