import React, { useContext } from 'react'
import Slider from 'react-slick'
import './ReviewCarousel.scss'
import Wave from '../../images/icons/Wave'
import { useMediaQuery } from 'react-responsive'
import Title from '../Title/Title'
import PagePadding from '../PagePadding/PagePadding'
import { HeaderContext } from '../Header/Header'

export default ({ className, cards }) => {
	const HeaderCTX = useContext(HeaderContext)

	const isMobile = useMediaQuery({
		query: '(max-width: 48rem)',
	})

	const isBigScreen = useMediaQuery({
		query: '(max-width: 64rem)',
	})

	function getValue() {
		if (isMobile) {
			return 1
		}

		if (isBigScreen) {
			return 2
		}

		return 2.5
	}

	function SamplePrevArrow(props) {
		const { className, onClick } = props
		return (
			<button className={className} onClick={onClick}>
				Vorige
			</button>
		)
	}

	function SampleNextArrow(props) {
		const { className, onClick } = props
		return (
			<button className={className} onClick={onClick}>
				Volgende
			</button>
		)
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: getValue(),
		slidesToScroll: 1,
		className: className,
		cssEase: 'cubic-bezier(.68,.63,.2,1.35)',
		arrows: true,
		prevArrow: <SamplePrevArrow />,
		nextArrow: <SampleNextArrow />,
	}
	return (
		<div className={'review__carousel--holder'}>
			<PagePadding>
				<Title heading={2} text={'Dit vinden anderen van InBalans'} />
			</PagePadding>
			<Slider {...settings}>
				{cards.length
					? cards.map((card) => {
							const { quote, cite } = card
							return (
								<blockquote
									key={cite}
									className="review__carousel--card review__carousel--blockquote"
								>
									<p>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 48 43"
											width={'48'}
											height={'43'}
											className={
												'review__carousel--blockquote-icon'
											}
										>
											<path
												fill={
													HeaderCTX.winterTime
														? '#EA862D'
														: 'rgba(0, 83, 148, 1)'
												}
												fillRule="evenodd"
												d="M18.3 25.11v17.82H0V25.11C0 16.85 4.37 7.94 12.64 0l7.29 5.83c-3.4 3.89-9.08 10.2-9.24 19.28h7.62zm27.55 0v17.82H27.54V25.11c0-8.26 4.37-17.17 12.64-25.11l7.29 5.83c-3.4 3.89-9.08 10.2-9.24 19.28h7.62z"
											/>
										</svg>
										<span
											className={
												'review__carousel--blockquote-text'
											}
										>
											{quote}
										</span>
									</p>
									<div
										className={
											'review__carousel--blockquote-bottom'
										}
									>
										<Wave winter={HeaderCTX.winterTime} />
										<footer>
											<p>
												<cite>{cite.name}</cite>
												<span> / {cite.age} jaar</span>
											</p>
											<p>{cite.experience}</p>
										</footer>
									</div>
								</blockquote>
							)
					  })
					: null}
			</Slider>
		</div>
	)
}
