import React, { useState } from 'react'
import './SkillCheck.scss'
import Title from '../Title/Title'

export default (props) => {
	const { experience } = props
	const [focused, setFocused] = useState(0)
	const experienceFilled = experience.filter((item) => {
		return item !== null
	})

	return !experienceFilled.length ? null : (
		<div className={'skill-check__holder'}>
			<Title
				text={'Niveau-meter'}
				wave={true}
				className={'skill-check__pretitle'}
			/>
			<ol className={'skill-check'}>
				{experienceFilled.map((level, i) => {
					return (
						<li
							key={`${level} - ${i}`}
							className={'skill-check__item'}
						>
							<button
								onFocus={() => setFocused(i)}
								className={`skill-check__button${
									focused === i ? ' active' : ''
								}`}
							>
								{i + 1}
							</button>
							<div className={'skill-check__content'}>
								<p className={'skill-check__title'}>
									Niveau {i + 1}
								</p>
								<p className={'skill-check__text'}>{level}</p>
							</div>
						</li>
					)
				})}
			</ol>
		</div>
	)
}
