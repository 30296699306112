import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import Clock from '../images/icons/Clock'
import Age from '../images/icons/Age'

export function getAllReviews(allWordpressAcfReview) {
	return allWordpressAcfReview.edges.map((review) => {
		return {
			quote: review.node.acf.review,
			cite: {
				age: review.node.acf.age,
				name: review.node.acf.name,
				level: review.node.acf.level,
			},
		}
	})
}

export function getCourseData(wordpressAcfCursus, HeaderCTX) {
	return {
		title: wordpressAcfCursus.acf.title_course,
		course_description: wordpressAcfCursus.acf.description,
		information: [
			{
				title: wordpressAcfCursus.acf.about_course.title_1,
				content: ReactHtmlParser(
					wordpressAcfCursus.acf.about_course.paragraph_1,
				),
			},
			{
				title: wordpressAcfCursus.acf.about_course.title_2,
				content: ReactHtmlParser(
					wordpressAcfCursus.acf.about_course.paragraph_2,
				),
			},
			{
				title: wordpressAcfCursus.acf.about_course.title_3,
				content: ReactHtmlParser(
					wordpressAcfCursus.acf.about_course.paragraph_3,
				),
			},
		],
		specs: [
			{
				label: wordpressAcfCursus.acf.lesson_amount
					? `${wordpressAcfCursus.acf.lesson_amount} lessen van ${wordpressAcfCursus.acf.lesson_duration} uur`
					: undefined,
				icon: <Clock winter={HeaderCTX.winterTime} />,
			},
			{
				label:
					wordpressAcfCursus.acf.age >= 17
						? 'Volwassenen'
						: !wordpressAcfCursus.acf.age
						? 'Iedereen'
						: 'Jeugd',
				icon: (
					<Age
						winter={HeaderCTX.winterTime}
						text={wordpressAcfCursus.acf.age}
					/>
				),
			},
		],
		data: {
			price: `${wordpressAcfCursus.acf.kosten}`,
			start_date: `${wordpressAcfCursus.acf.start_date}`,
		},
	}
}

export function getAllDays(wordpressAcfCursus) {
	return wordpressAcfCursus.acf.times !== null &&
		wordpressAcfCursus.acf.times.body &&
		wordpressAcfCursus.acf.times.body.length
		? wordpressAcfCursus.acf.times.body
		: []
}

export function getAllCourseHeaders(wordpressAcfCursus) {
	return wordpressAcfCursus.acf.times !== null &&
		wordpressAcfCursus.acf.times.header &&
		wordpressAcfCursus.acf.times.header.length
		? wordpressAcfCursus.acf.times.header.map((header) => header.c)
		: []
}

export function filterEmptyDays(variationDays) {
	return variationDays.filter((d) => !d[0].body[0].every((f) => !f.c))
}

export function getAllVariations(wordpressAcfCursus) {
	return wordpressAcfCursus.acf.variations &&
		wordpressAcfCursus.acf.variations.times &&
		wordpressAcfCursus.acf.times &&
		wordpressAcfCursus.acf.times.body &&
		wordpressAcfCursus.acf.times.body.length
		? wordpressAcfCursus.acf.variations.map((variation) => [
				variation.times,
				variation.naam_variatie,
		  ])
		: []
}
